import React from "react";

const Login = () => {
    return (
        <div className="w-full flex h-screen items-center justify-center">
            <div className="p-24 border rounded-md bg-orange-100">
               This is the Admin Login area!
            </div>
        </div>
    )
}

export default Login;
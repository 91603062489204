import React from "react";

const Home = () => {
    return (
        <div className="w-full flex h-screen items-center justify-center">
            <div className="p-24 border rounded-md bg-blue-100">
                The listing will be displayed here!
            </div>
        </div>
    )
}

export default Home;